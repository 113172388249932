import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Create from "./components/Create";
import Edit from "./components/Edit";
import InfraCobertizos from "./components/asistencias";
import Navbar from "./components/Navbar";
import Show from "./components/Show";
import LoginPage from "./page/LoginPage";
import PrivateRoute from "./router/PrivateRoute";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Navbar />} />
          <Route
            exact
            path="/show"
            element={<PrivateRoute component={Show} />}
          />
          <Route
            exact
            path="/create"
            element={<PrivateRoute component={Create} />}
          />
          <Route
            exact
            path="/edit/:id"
            element={<PrivateRoute component={Edit} />}
          />
          
         

          
          <Route
            exact
            path="/asistencia"
            element={<PrivateRoute component={InfraCobertizos} />}
          />
          
          

          <Route exact path="/login" element={<LoginPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
