import {  Navigate } from "react-router-dom";
import { auth } from '../firebase/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';

const PrivateRoute = ({ component: Compontent }) => {
  const [user] = useAuthState(auth);

  return user ? (
    <Compontent />
  ) : (
    <Navigate to="/login" replace />
  );
}

export default PrivateRoute;